import { map, union, isEqual } from 'lodash';
import {
  fetchCompetitionSubscription,
  fetchEventSubscription,
  bookEvent,
  bookSameGameParlays,
  bookPlayerProps,
  bookMicroMarkets,
  unbookEvent,
  unbookSameGameParlays,
  unbookPlayerProps,
  unbookMicroMarkets,
  subscribe,
  subscribeSameGameParlays,
  subscribePlayerProps,
  subscribeMicroMarkets,
  unsubscribe,
  unsubscribeSameGameParlays,
  unsubscribePlayerProps,
  unsubscribeMicroMarkets,
  updateCompetitionFeedConfiguration,
} from '@/services/api';

export const subscriptionTypes = {
  COMPETITION: 'COMPETITION',
  EVENT: 'EVENT',
};

export const pricingModeTypes = {
  AUTO: 'AUTO',
};

export const marketCategoryTypes = {
  CORE_AND_DERIVATIVES: 'CORE_AND_DERIVATIVES',
  SAME_GAME_PARLAYS: 'SAME_GAME_PARLAYS',
  PLAYER_PROPS: 'PLAYER_PROPS',
  MICROS: 'MICROS',
};

export const allMarketCategories = {
  [marketCategoryTypes.CORE_AND_DERIVATIVES]: {
    id: marketCategoryTypes.CORE_AND_DERIVATIVES,
    label: 'Core & derivative markets',
  },
  [marketCategoryTypes.SAME_GAME_PARLAY]: {
    id: marketCategoryTypes.SAME_GAME_PARLAYS,
    label: 'Same game parlay markets',
  },
  [marketCategoryTypes.PLAYER_PROPS]: {
    id: marketCategoryTypes.PLAYER_PROPS,
    label: 'Player prop markets',
  },
  [marketCategoryTypes.MICROS]: {
    id: marketCategoryTypes.MICROS,
    label: 'Micro markets',
  },
};

export const allPricingModes = {
  [pricingModeTypes.AUTO]: {
    id: pricingModeTypes.AUTO,
    label: 'Mercury',
    shortLabel: 'Mercury',
  },
};

export const makeCompetitionSubscriptionRecord = (competition) => ({
  id: competition.competitionId,
  pricingMode: competition.core.nodes?.[0]?.pricingMode || '',
  core: !!competition.core.totalCount,
  sameGameParlays: !!competition.sameGameParlays.totalCount,
  playerProps: !!competition.playerProps.totalCount,
  micro: !!competition.micro.totalCount,
  marketCategories: union(...map(
    competition.feedConfigurations.nodes || [],
    (feedConfiguration) => feedConfiguration.marketCategories,
  )),
  feedPriorities: competition.feedConfigurations.nodes || [],
  isBooked: !!competition.core?.totalCount,
});

export const makeEventSubscriptionRecord = (event) => ({
  id: event.eventId,
  pricingMode: event.core.nodes?.[0]?.pricingMode || '',
  core: !!event.core.totalCount,
  sameGameParlays: !!event.sameGameParlays.totalCount,
  playerProps: !!event.playerProps.totalCount,
  micro: !!event.micro.totalCount,
  marketCategories: union(...map(
    event.competition.feedConfigurations.nodes || [],
    (feedConfiguration) => feedConfiguration.marketCategories,
  )),
  feedPriorities: [],
  isBooked: !!event.core?.totalCount,
});

export const makeSubscriptionRecord = (type, entity) => {
  switch (type) {
  case subscriptionTypes.COMPETITION:
    return makeCompetitionSubscriptionRecord(entity);
  case subscriptionTypes.EVENT:
    return makeEventSubscriptionRecord(entity);
  default:
    return {};
  }
};

export const fetchSubscriptionRecord = async (type, id) => {
  switch (type) {
  case subscriptionTypes.COMPETITION:
    const { data: { competition } } = await fetchCompetitionSubscription(id);
    if (!competition) return null;
    return makeCompetitionSubscriptionRecord(competition);
  case subscriptionTypes.EVENT:
    const { data: { event } } = await fetchEventSubscription(id);
    if (!event) return null;
    return makeEventSubscriptionRecord(event);
  default:
    return {};
  }
};

export const saveCoreAndDerivatives = (type, originalRecord, newRecord) => {
  if (originalRecord.core === newRecord.core) return Promise.resolve();
  if (type === subscriptionTypes.EVENT) {
    if (newRecord.core) return bookEvent(newRecord.id, newRecord.pricingMode);
    return unbookEvent(newRecord.id);
  }
  if (type === subscriptionTypes.COMPETITION) {
    if (newRecord.core) return subscribe(newRecord.id, newRecord.pricingMode);
    return unsubscribe(newRecord.id);
  }
  return Promise.resolve();
};

export const saveSameGameParlays = (type, originalRecord, newRecord) => {
  if (originalRecord.sameGameParlays === newRecord.sameGameParlays) return Promise.resolve();
  if (type === subscriptionTypes.EVENT) {
    if (newRecord.sameGameParlays) return bookSameGameParlays(newRecord.id);
    return unbookSameGameParlays(newRecord.id);
  }
  if (type === subscriptionTypes.COMPETITION) {
    if (newRecord.sameGameParlays) return subscribeSameGameParlays(newRecord.id);
    return unsubscribeSameGameParlays(newRecord.id);
  }
  return Promise.resolve();
};

export const savePlayerProps = (type, originalRecord, newRecord) => {
  if (originalRecord.playerProps === newRecord.playerProps) return Promise.resolve();
  if (type === subscriptionTypes.EVENT) {
    if (newRecord.playerProps) return bookPlayerProps(newRecord.id);
    return unbookPlayerProps(newRecord.id);
  }
  if (type === subscriptionTypes.COMPETITION) {
    if (newRecord.playerProps) return subscribePlayerProps(newRecord.id);
    return unsubscribePlayerProps(newRecord.id);
  }
  return Promise.resolve();
};

export const saveMicros = (type, originalRecord, newRecord) => {
  if (originalRecord.micro === newRecord.micro) return Promise.resolve();
  if (type === subscriptionTypes.EVENT) {
    if (newRecord.micro) return bookMicroMarkets(newRecord.id);
    return unbookMicroMarkets(newRecord.id);
  }
  if (type === subscriptionTypes.COMPETITION) {
    if (newRecord.micro) return subscribeMicroMarkets(newRecord.id);
    return unsubscribeMicroMarkets(newRecord.id);
  }
  return Promise.resolve();
};

export const savePricingMode = (type, originalRecord, newRecord) => {
  if (originalRecord.pricingMode === newRecord.pricingMode) return Promise.resolve();
  if (type === subscriptionTypes.EVENT) return bookEvent(newRecord.id, newRecord.pricingMode);
  if (type === subscriptionTypes.COMPETITION) return subscribe(newRecord.id, newRecord.pricingMode);
  return Promise.resolve();
};

export const saveFeedPriorities = (type, originalRecord, newRecord) => {
  if (isEqual(originalRecord.feedPriorities, newRecord.feedPriorities)) return Promise.resolve();
  return updateCompetitionFeedConfiguration(newRecord.feedPriorities);
};

export const saveSubscriptions = (type, originalRecord, newRecord) => Promise.all([
  saveCoreAndDerivatives(type, originalRecord, newRecord),
  saveSameGameParlays(type, originalRecord, newRecord),
  savePlayerProps(type, originalRecord, newRecord),
  saveMicros(type, originalRecord, newRecord),
  savePricingMode(type, originalRecord, newRecord),
  saveFeedPriorities(type, originalRecord, newRecord),
]);
