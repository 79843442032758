import { padStart, split } from 'lodash';

export const convertTo24HourTime = (twelveHourTime) => {
  const [time, period] = split(twelveHourTime || '', ' ');
  const [hours, minutes] = split(time || '', ':').map(Number);

  let correctedHours = hours;
  if (period === 'PM' && hours !== 12) correctedHours += 12;
  if (period === 'AM' && hours === 12) correctedHours = 0;

  const correctedHoursAsString = padStart(correctedHours, 2, '0');
  const minutesAsString = padStart(minutes, 2, '0');
  return `${correctedHoursAsString}:${minutesAsString}`;
};
